import Vue from 'vue';
import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';
import utils from '@/common/js/utils';

if (!utils.is_weixin()) {
    Vue.use(Viewer);
    Viewer.setDefaults({
        navbar: false,
        url: 'data-preview',
        minWidth: '100%',
        title: false,
        tooltip: false,
        toolbar: {
            prev: true,
            next: true,
        },
    });
} else {
    Vue.directive('viewer', {
        bind(el) {
            const urlList = Array.from(el.querySelectorAll('img')).map((imgEl) => imgEl.getAttribute('data-preview'));

            el.addEventListener('click', (event) => {
                const ev = event || window.event;
                const target = ev.target || ev.srcElement;
                if (target.nodeName.toLowerCase() === 'img') {
                    const currentUrl = target.getAttribute('data-preview');
                    wx.previewImage({ current: currentUrl, urls: urlList });
                }
            });
        },
    });
}
